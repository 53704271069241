import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Spin, ConfigProvider } from "antd";
import Logon from "./pages/Logon";
import { config, deployments } from "./constants";
import { CenterBox } from "./components/Styles";
import UserContext from "./context";
import ChatGPT from "./pages/ChatGPT";
import { ThemeProvider } from "styled-components";
import Theme, { ThemeToken } from "./stylesheets/Theme";
import GlobalStyles from "./stylesheets/Global.styled";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoader, setShowLoader] = useState(true); // Application Loading Boolean
  //const [user, setUser] = useState(null);

  const [userContext, setUserContext] = useState({ lastSelectedModel: deployments[2].value, user: null });
  useEffect(() => {
    if (Cookies.get("auth_user")) {
      //console.log('auth check cookie found');
      setIsLoggedIn(true);
    }
    // else
    //   console.log('auth check cookie not found');
    setShowLoader(false);
  }, []);

  const handleLogout = () => {
    //console.log("e", config.ENV);
    if (config.ENV === "development") Cookies.remove("auth_user");
    else Cookies.remove("auth_user", { domain: ".bold.com" });
    setIsLoggedIn(false);
  };

  return (
    <Suspense fallback={<Spin tip="Loading" size="large" />}>
      <UserContext.Provider value={{ userContext, setUserContext }}>
        <ThemeProvider theme={Theme}>
          <ConfigProvider
            theme={{
              token: ThemeToken,
            }}
          >
            <GlobalStyles />
            {showLoader ? (
              <CenterBox>
                <Spin tip="Loading" size="large" />
              </CenterBox>
            ) : (
              <>
                <Routes>
                  <Route path="/" index element={isLoggedIn ? <ChatGPT isLoggedIn={isLoggedIn} handleLogout={handleLogout} /> : <Logon />} />
                </Routes>
              </>
            )}
          </ConfigProvider>
        </ThemeProvider>
      </UserContext.Provider>
    </Suspense>
  );
}

export default App;
