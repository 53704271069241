import React, { useRef, useEffect } from "react";
import { Card, Form, Tooltip, Checkbox, Input, Row, Col, Button } from "antd";
import { ControlOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import GenericStep from "../common/GenericStep";

const ChatParameter = (props) => {
  const {stateBindToDeploymentModel, setOpenParameter, openParameter} = props;

  const parameterCardRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (openParameter) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      !openParameter && document.removeEventListener('click', handleClickOutside);
    };
  }, [openParameter]);

  const handleClickOutside = (event) => {
    if (parameterCardRef.current && !parameterCardRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
      setOpenParameter(false);
    }
  };

  return (
    <ChatParaMeterStyled>
      {openParameter &&
      <ParmeterCardStyled ref={parameterCardRef} title={<><ControlOutlined /> Parameter</>}>
        <div className="slider-wrapper">
          <Form.Item
            className="form-slider"
            label={
              <>
                Temperature{" "}
                <Tooltip
                  title={`Controls randomness. Lowering the temperature means that the model will produce more repetitive and deterministic responses. Increasing the temperature will result in more unexpected or creative responses.
                        Try adjusting temperature or Top P but not both.`}
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <GenericStep min={0} max={1} step={0.01} name="temperature" trackStyle={"brandTeal"} railStyle={"white"} handleStyle={"brandTeal"} />
          </Form.Item>
          <Form.Item
            className="form-slider"
            label={
              <>
                Max length (tokens){" "}
                <Tooltip
                  title={`Set a limit on the number of tokens per model response. The API supports a maximum of ${stateBindToDeploymentModel.deployment_max_token_limit} tokens
                      shared between the prompt (including system message, examples, message history, and user query) and the model's response. One token is roughly 4 characters for typical English text.`}
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <GenericStep name="max_tokens" max={stateBindToDeploymentModel.deployment_max_token_limit} trackStyle={"brandBlue"} railStyle={"white"} handleStyle={"brandBlue"}/>
          </Form.Item>
          <Form.Item
            className="form-slider"
            label={
              <>
                Top probabilities
                <Tooltip
                  title={`Similar to temperature, this controls randomness but uses a different method. 
                        Lowering Top P will narrow the model’s token selection to likelier tokens. Increasing Top P will let the model
                        choose from tokens with both high and low likelihood. Try adjusting temperature or Top P but not both.`}
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <GenericStep min={0} max={1} step={0.01} name="top_p" trackStyle={"brandGreen"} railStyle={"white"} handleStyle={"brandGreen"} />
          </Form.Item>
          {stateBindToDeploymentModel && stateBindToDeploymentModel.hideFormElements &&  !stateBindToDeploymentModel?.hideFormElements.includes("max_history") && (
            <Form.Item
              className="form-slider"
              label={
                <>
                  Context History
                  <Tooltip
                    title={`Controls number of previos messages passed for setting context. 
                          You can get better results by setting it to maximum, If you get an error of maximum request size, reduce it.`}
                  >
                    <ExclamationCircleOutlined />
                  </Tooltip>
                </>
              }
            >
              <GenericStep name="max_history" trackStyle={"brandOrange"} railStyle={"white"} handleStyle={"brandOrange"} />
            </Form.Item>
          )}
          {stateBindToDeploymentModel && stateBindToDeploymentModel.hideFormElements &&  !stateBindToDeploymentModel?.hideFormElements.includes("frequency_penalty") && (
            <Form.Item
              className="form-slider"
              label={
                <>
                  Frequency penalty
                  <Tooltip
                    title={`Reduce the chance of repeating a token proportionally based on how often it has appeared in the text so far.
                        This decreases the likelihood of repeating the exact same text in a response.`}
                  >
                    <ExclamationCircleOutlined />
                  </Tooltip>
                </>
              }
            >
              <GenericStep min={0} max={1} step={0.01} name="frequency_penalty" trackStyle={"brandOrange"} railStyle={"white"} handleStyle={"brandOrange"} />
            </Form.Item>
          )}
          {stateBindToDeploymentModel && stateBindToDeploymentModel.hideFormElements && !stateBindToDeploymentModel?.hideFormElements.includes("presence_penalty") && (
            <Form.Item
              className="form-slider"
              label={
                <>
                  Presence penalty
                  <Tooltip
                    title={`Reduce the chance of repeating any token that has appeared in the text at all so far.
                        This increases the likelihood of introducing new topics in a response.`}
                  >
                    <ExclamationCircleOutlined />
                  </Tooltip>
                </>
              }
            >
              <GenericStep min={0} max={1} step={0.01} name="presence_penalty" trackStyle={"brandOrange"} railStyle={"white"} handleStyle={"brandOrange"}/>
            </Form.Item>
          )}
          {stateBindToDeploymentModel && stateBindToDeploymentModel.hideFormElements && !stateBindToDeploymentModel?.hideFormElements.includes("best_of") && (
            <Form.Item
              className="form-slider"
              label={
                <>
                  Best of
                  <Tooltip
                    title={`Generate multiple responses, and display only the one with the best total probability across all its tokens.
                          The unused candidates will still incur usage costs, so use this parameter carefully and make sure to set the parameters for 
                          max response length and ending triggers as well.
                          `}
                  >
                    <ExclamationCircleOutlined />
                  </Tooltip>
                </>
              }
            >
              <GenericStep name="best_of" trackStyle={"brandOrange"} railStyle={"white"} handleStyle={"brandOrange"} />
            </Form.Item>
          )}
        </div>
        <Form.Item
          label={
            <>
              Stop sequences{" "}
              <Tooltip
                title={`Make responses stop at a desired point, such as the end of a sentence or list.
                      Specify up to four sequences(comma seperated) where the model will stop generating further tokens in a response.
                      The returned text will not contain the stop sequence.`}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            </>
          }
          name="stop"
        >
          <Input placeholder="Comma seperated stop words" />
        </Form.Item>
        <Form.Item
          className="single-input"
          label={
            <>
              Pre-response text
              <Tooltip title={`Insert text after the user’s input and before the model’s response. This can help prepare the model for a response.`}>
                <ExclamationCircleOutlined />
              </Tooltip>
            </>
          }
        >
          <Row gap={10}>
            <Col span={2}>
              <Form.Item name="pre_text_check" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={21} push={1}>
              <Form.Item name="pre_text">
                <Input placeholder="Response prefix text" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
        className="single-input"
          label={
            <>
              Post-response text
              <Tooltip title={`Insert text after the model’s generated response to encourage further user input, as when modeling a conversation.`}>
                <ExclamationCircleOutlined />
              </Tooltip>
            </>
          }
        >
          <Row>
            <Col span={2}>
              <Form.Item name="post_text_check" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={21} push={1}>
              <Form.Item name="post_text">
                <Input placeholder="Response postfix text" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </ParmeterCardStyled>
      }
      <Tooltip title={"GPT Parameters"} placement="left">
        <Button ref={buttonRef} icon={<ControlOutlined />} shape="circle" className="parameter-button" onClick={()=>setOpenParameter(!openParameter)}></Button>
      </Tooltip>
    </ChatParaMeterStyled>
  );
};

const ChatParaMeterStyled = styled.div`
.parameter-button{
    position: fixed;
    bottom: 10px;
    right: 10px;
    span{
      transform: rotate(90deg);
    }
  }
`
const ParmeterCardStyled = styled(Card)`
  position: fixed;
  width: 370px;
  right: 15px;
  bottom: 55px;
  max-height: 85%;
  overflow-y: auto;
  z-index: 2;
  background-color: ${props => props.theme.colors.backgroundSemiLight};
  box-shadow: 0px 4px 20px 0px rgba(71, 94, 132, 0.3);
  border-radius: 10px;
  border: 1px solid rgba(71, 94, 132, 0.302);
  .form-slider:nth-child(4n + 1){
    .ant-slider-handle{
        &::after, &:active::after, &:focus::after, &:hover::after{
            box-shadow: 0 0 0 4px ${props => props.theme.colors.brandTeal};
        }
    }
    .ant-slider-track{
      background-color: ${props => props.theme.colors.brandTeal}
    }
  }
  .form-slider:nth-child(4n + 2){
    .ant-slider-handle{
        &::after, &:active::after, &:focus::after, &:hover::after{
            box-shadow: 0 0 0 4px ${props => props.theme.colors.brandBlue};
        }
    }
    .ant-slider-track{
      background-color: ${props => props.theme.colors.brandBlue}
    }
  }
  .form-slider:nth-child(4n + 3){
    .ant-slider-handle{
        &::after, &:active::after, &:focus::after, &:hover::after{
            box-shadow: 0 0 0 4px ${props => props.theme.colors.brandGreen};
        }
    }
    .ant-slider-track{
      background-color: ${props => props.theme.colors.brandGreen}
    }
  }
  .form-slider:nth-child(4n + 4){
    .ant-slider-handle{
        &::after, &:active::after, &:focus::after, &:hover::after{
            box-shadow: 0 0 0 4px ${props => props.theme.colors.brandOrange};
        }
    }
    .ant-slider-track{
      background-color: ${props => props.theme.colors.brandOrange}
    }
  }
  .ant-card-head {
    border-bottom: none;
    min-height: unset;
    padding: 10px 16px;
    font-size: 24px;
    color: #000;
    font-weight: 400;
  }
  .ant-card-body{
    padding: 0px 16px 10px;
    & .ant-form-item{
        margin-bottom: 12px;
        & * {
          color: #000;
        }
        .ant-form-item-label{
          padding-bottom: 0px;
          vertical-align: middle;
          span{
            margin-left: 5px;
          }
        }
        & .ant-form-item{
            margin-bottom: 0px;
        }
        &.single-input .ant-form-item-label{
          padding-bottom: 5px;
        }
      .ant-checkbox-inner{
        width: 30px;
        height: 30px;
        margin-top: 0px;
        &:after{
          inset-inline-start: 32%;
        }
      }
    }
  }
  .ant-input-number{
    width: 60px;
    box-shadow: none;
    outline: none;
    border: none;
  }
  .ant-input, .ant-input-number-input{
    &:focus{
      box-shadow: none;
      outline: none;
    }
  }
`;

export default ChatParameter;
