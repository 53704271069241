import { defaultParams, deployments } from "../../constants";


const getFormDefaultValues = () => {
  var index = deployments.findIndex(x=> x.value =='gpt-4o-mini')
  return {
    deployment: deployments[index].value,
    deployment_max_token_limit: deployments[index].max_token_limit,
    ...defaultParams[0],
    prompt: "",
    query_name: "",
    cost: 0,
    chats: [],
    id: 0,
    usageInstructions: deployments[index].usageInstructions,
  };
};

const getNewState = (initValues) => {
  const h = [],
    s = [];
  let maxFileUploadCount = 0;
  if (initValues.deployment === "gpt-35-turbo-chatgpt" || initValues.deployment === "gpt-4o") {
    h.push("best_of");
  }
  if (initValues.deployment === "gemini-pro") {
    h.push("frequency_penalty");
    h.push("presence_penalty");
  }
  if (initValues.usageInstructions) {
    s.push("showUseWithCautionWarning");
  }
  if (initValues.deployment == "gemini-15-flash") {
    maxFileUploadCount = 16;
  }
  if (initValues.deployment == "gpt-4o") {
    maxFileUploadCount = 10;
  }

  let uploadFileTypeSupported = ["image/*", "video/*", "application/pdf"];

  // if (initValues.deployment == "gemini-15-flash") {
  //   uploadFileTypeSupported = [
  //     ...uploadFileTypeSupported,
  //     "video/*",
  //     "application/pdf",
  //   ];
  // }

  return {
    hideFormElements: h,
    showFormElememts: s,
    deployment_max_token_limit: initValues.deployment_max_token_limit,
    sessionStartIndex: 0,
    maxFileUploadCount,
    deployment: initValues.deployment,
    uploadFileTypeSupported,
    deprecate: initValues.deprecate,
  };
};


const defaultMultiModalMessage = () => ( {
    role: "user",
    contentItems: [],
  });

const convertToHTML = (inputString) => {
  if (!inputString) return;
  
  //console.log(inputString)
  let html = inputString.replace(/(https?:\/\/[^\s]+)/g, function (url) {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });

  // Convert headers (#) to <h1>, (##) to <h2>, (###) to <h3>, etc.
  html = html.replace(/^(#{1,6})\s*(.*)$/gm, (match, hashes, text) => {
    const level = hashes.length;
    return `<h${level}>${text}</h${level}>`;
  });

  // Convert unordered list items (- or *) to <ul> and <li>
  html = html.replace(/^\s*[-*]\s+(.+)$/gm, "<li>$1</li>");

  // Combine consecutive list items into a single <ul>
  html = html.replace(/(<li>.*<\/li>)+/g, (match) => `<ul>${match}</ul>`);
  html = html.replace(/<\/ul>\s*<ul>/g, ""); // Remove nested <ul> tags if needed

  // Convert bold text (**) to <strong>
  html = html.replace(/\*\*(.+?)\*\*/g, "<strong>$1</strong>");

  // Detect incomplete HTML blocks and handle missing closing tags for <html>, <body>
  html = html.replace(/(?:<\s*(!DOCTYPE|html|head|body|table)[^>]*>)[\s\S]*?(<\/html>|<\/body>|<\/head>|<\/table>|$)/gi, (match) => {
    const isComplete = match.endsWith('</html>') || match.endsWith('</body>') || match.endsWith('</head>') || match.endsWith('</table>');
    const escapedCode = match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return `<pre><code>${escapedCode}</code></pre>`;
  });

  // Detect React/JSX return() blocks, even incomplete ones, and wrap them in <pre><code>
  html = html.replace(/return\s*\(\s*([\s\S]*?)(?=\);|$)/g, (match, jsxCode) => {
    const escapedJSX = jsxCode.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return `return (<pre><code>${escapedJSX}</code></pre>);`;
  });

  // Detect .NET code blocks (e.g., Razor syntax, @page, @model, @{ })
  html = html.replace(/@page|@model|@\{[\s\S]*?\}/g, (match) => {
    const escapedCode = match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return `<pre><code>${escapedCode}</code></pre>`;
  });

  // Escape inline code wrapped in backticks (`code`)
  html = html.replace(/`([^`]+)`/g, (match, code) => {
    const escapedCode = code.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return `<code>${escapedCode}</code>`;
  });

  // Split by two or more new lines to create separate paragraphs
  const paragraphs = html.split(/\n{2,}/);

  // Process each paragraph to ensure only block-level elements remain outside <p> tags
  html = paragraphs
    .map((paragraph) => {
      paragraph = paragraph.trim();
      // If the paragraph starts with a block-level element (like <h>, <ul>, <pre>, <table>, etc.), return it as is
      if (paragraph.match(/^(<(h[1-6]|ul|pre|table|form|tr|td|th)>)/)) {
        return paragraph;
      }
      // Otherwise, wrap the content in <p> tags
      return paragraph ? `<p>${paragraph}</p>` : "";
    })
    .filter(paragraph => paragraph) // Filter out empty paragraphs
    .join("\n");

  return html;
};

export { getFormDefaultValues, getNewState, defaultMultiModalMessage, convertToHTML };
