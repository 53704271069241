import React from "react";
import { Avatar, Button, Skeleton } from "antd";
import { UserOutlined, EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import FormattedMimeMessage from "./FormattedMimeMessage";
import { convertToHTML } from "../common/ChatHelper";

const ChatsBox = ({ messages, loading, setInput, inputPromptRef }) => {

  const onEditPromptClick = (content) => {
    if(content.indexOf('#MMM#') === -1){
      setInput(content)
      inputPromptRef?.current?.focus({
        cursor: 'end',
      });
    }
    return;
  }
  

  return (
    <ChatsBoxStyled>
      {messages.map((message, index) => (
        <div className={`message ${message.role == "user" ? "right" : "left"}`} key={index}>
          {message.role == "user" ? <Avatar icon={<UserOutlined />} /> : <div className="avatar"></div>}
          <div className="text-and-icon-wrapper">
            {message.role == "user" && <Button type="text" onClick={()=> onEditPromptClick(message.content)} shape="circle" icon={<EditOutlined />} className="edit-on-hover"></Button>}
            <div className="text-wrapper">
              {
                message && message.content && message.content.indexOf('#MMM#') === -1 ?
                <div className="text" dangerouslySetInnerHTML={{ __html: convertToHTML(message.content) }}>
                  {/* {message.content} */}
                </div>
                :
                <FormattedMimeMessage message={message.content} />

              }
              {/* <div className="timestamp">17 Aug, 8:04 pm</div> */}
            </div>
          </div>
        </div>
      ))}
      {loading && (
        <div className={`message left`}>
          <div className="avatar"></div>
          <Skeleton active />
        </div>
      )}
    </ChatsBoxStyled>
  );
};

const ChatsBoxStyled = styled.div`
  width: 100%;
  padding-top: 50px;
  max-width: 750px;
  margin: 0 auto;
  .ant-avatar.ant-avatar-icon {
    background-color: ${(props) => props.theme.colors.primary};
    height: 35px;
    width: 35px;
    flex-shrink: 0;
  }
  .text-and-icon-wrapper{
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 85%;
    .edit-on-hover{
      opacity: 0;
      visibility: hidden;
    }
    &:hover .edit-on-hover{
      opacity: 1;
      visibility: visible;
    }
  }
  .message {
    display: flex;
    margin-bottom: 40px;
    transition: all 0.5s linear;
    gap: 20px;
    align-items: flex-start;
  }

  .appeared {
    opacity: 1;
  }
  .timestamp {
    font-size: 10px;
    font-weight: 300;
    margin-top: 5px;

    & a {
      color: ${(props) => props.theme.colors.brandTeal};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .right {
    flex-direction: row-reverse;
    .text-wrapper {
      border-radius: 10px;
      border: 1px solid rgba(71, 94, 132, 0.3);
      background: #fff;
      padding: 12px 16px;
    }
    .text {
      white-space: pre-wrap;
    }
  }
  .left {
    a {
      color: ${(props) => props.theme.colors.brandOrange};
      :hover {
        text-decoration: underline;
      }
    }
    .text{
      > * {
        white-space: pre-wrap;
        margin-bottom: 10px;
      }
    }
    h1, h2, h3, h4, h5, h6, strong  {
      font-weight: 600;
    }
    ul {
      list-style: inside disc;

      li {
        padding-left: 10px;
      }
    }
    .avatar {
      background-image: url("../icon-bold.svg");
      border-radius: 50px;
      border: 1px solid rgba(87, 108, 141, 0.5);
      background-color: #fff;
      background-size: 60%;
      height: 35px;
      width: 35px;
      background-repeat: no-repeat;
      background-position: center center;
      flex-shrink: 0;
    }
  }
`;

export default ChatsBox;
