import { config, deployments } from "../constants";
import Cookies from "js-cookie";

const authToken = () => `Bearer ${Cookies.get("auth_user")}`;
const getGptVersion = (model, target) => {
  const apiT = deployments.find((x) => x.value === model)?.apiTarget;
  return apiT ? apiT[target] : null
};

async function getUserProfile() {
  const res = await fetch(`${config.API_HOST}${config.USER_PROFILE_PATH}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
  });
  const data = await res.json();
  if (data.code) {
    console.log("Authentication failed with status code", data.code);
    return null;
  }
  return data;
}

//#region chat interface result
async function rawChatCompletions(deployment, chatRequestJson) {
  //example requestJson
  //{"messages":[{"role":"user","content":"answer questions about india"}],"temperature":0.7,"top_p":0.95,"frequency_penalty":0,"presence_penalty":0,"max_tokens":800,"stop":null}

  let urlPath = config.CHAT_COMPLETION_PATH.replace(
    "deployment_name",
    deployment
  );
  const v = getGptVersion(deployment, 'c');
  if (v === 'MULTI_MODAL_CHAT_COMPLETION_PATH') {
    urlPath = config.MULTI_MODAL_CHAT_COMPLETION_PATH.replace(
      "deployment_name",
      deployment
    );
  }

  const res = await fetch(`${config.API_HOST}${urlPath}`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(chatRequestJson),
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
  });
  let data = await res.json();
  //console.log(data)
  return data;
}
//#endregion chat completions endpoint

//#region chat history api

async function createNewChatHistory(
  loggedInUser,
  chatTitle,
  deploymentNameUsed,
  paramsUsed = null
) {
  let jsonBody = {
    name: chatTitle,
    deployment: deploymentNameUsed,
    params: paramsUsed,
    chats: [],
  };

  const res = await fetch(`${config.API_HOST}/chatHistory`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(jsonBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
      userId: loggedInUser.id,
    },
  });
  const data = await res.json();
  return data;
}

async function getAllChatTitlesForUser(loggedInUser) {
  var url = `${config.API_HOST}/chatHistory/user/${loggedInUser.id}/titles`;
  const res = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: authToken(),
    },
  });
  const data = await res.json();

  if (data.code) {
    console.log("Request failed with status code", data.code);
    return null;
  }
  return data;
}
async function getChatHistoryById(chatHistoryId) {
  const url = `${config.API_HOST}/chatHistory/${chatHistoryId}`;
  const res = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: authToken(),
    },
  });
  const data = await res.json();

  if (data.code) {
    console.log("Request failed with status code", data.code);
    return null;
  }
  //example data
  //{"chats":[{"try1":"1"},{"try2":"2"}],"params":{},"id":2,"userid":6,"name":"test try 1","deployment":"chat-gpt ","createdAt":"2023-08-15T15:42:01.907Z","updatedAt":"2023-08-15T17:56:31.434Z"}
  return data;
}
async function deleteChatHistoryById(chatHistoryId, chatThreadToAdd) {
  var url = `${config.API_HOST}/chatHistory/${chatHistoryId}`;
  const res = await fetch(url, {
    method: "DELETE",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: authToken(),
    },
  });
  const data = await res.json();

  if (data.code) {
    console.log("Request failed with status code", data.code);
    return false;
  }
  return true;
}
//Chat Threads
async function updateChatThreadById(chatHistoryId, chatThreadToAdd) {
  const res = await fetch(
    `${config.API_HOST}/chatHistory/${chatHistoryId}/chats/add`,
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(chatThreadToAdd),
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken(),
      },
    }
  );
  const data = await res.json();
  return data;
}
async function updateChatTitleById(chatHistoryId, newChatTite) {
  var url = `${config.API_HOST}/chatHistory/${chatHistoryId}/title`;
  const res = await fetch(url, {
    method: "PATCH",
    mode: "cors",
    body: JSON.stringify({ newName: newChatTite }),
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
  });
  const data = await res.json();
  return data;
}

//#endregion chat history api

export {
  getUserProfile,
  rawChatCompletions,
  createNewChatHistory,
  getAllChatTitlesForUser,
  getChatHistoryById,
  updateChatThreadById,
  updateChatTitleById,
  deleteChatHistoryById,
};
