import React, { useState, useContext, useEffect, useRef } from "react";
import { Button, Form, Layout, Menu, Modal, notification, Skeleton, Tooltip } from "antd";
import { ExclamationCircleFilled} from "@ant-design/icons";
import styled from "styled-components";
import { defaultParams, deployments, getDeploymentByValue } from "../constants";
import UserContext from "../context";
import { defaultMultiModalMessage, getFormDefaultValues, getNewState } from "../components/common/ChatHelper";
import { createNewChatHistory, deleteChatHistoryById, getAllChatTitlesForUser, getChatHistoryById, rawChatCompletions, updateChatThreadById, updateChatTitleById } from "../service";
import SideBar from "../components/common/SideBar";
import NewChatBox from "../components/common/NewChatBox";
import ChatsBox from "../components/chat/ChatsBox";
import InputPromptBox from "../components/common/InputPromptBox";
import ChatParameter from "../components/chat/ChatParameter";
import UserNavDropdown from "../components/header/UserNavDropdown";
import EmptyChatLoader from "../components/common/EmptyChatLoader";
import EditableChatName from "../components/chat/EditableChatName";

const { confirm } = Modal;

const ChatGPT = (props) => {
  const { isLoggedIn, handleLogout } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [formInitValues, setFormInitValues] = useState(getFormDefaultValues());
  const [selectedKeys, setSelectedKeys] = useState(null);
  const [showChatLoader, setShowChatLoader] = useState(true); // This is being used SideNav Chats List Loading // main page loader
  const [showLoaderInSideMenu, setShowLoaderInSideMenu] = useState(false); // This is used for First Time or When a New Chat is added for SideNav Chats List Loading //side nav loader
  const [navItems, setNavItems] = useState(null);
  const [promptsList, setPromptsList] = useState(null);
  const [input, setInput] = useState("");
  const [fileList, setFileList] = useState([]);


  const [newUserMessage, setNewUserMessage] = useState(defaultMultiModalMessage());
  const [chatBoxState, setChatBoxState] = useState(null);
  const [selectedChatHistoryValue, setSelectedChatHistoryValue] = useState({ chats: [], id: null });
  const [messages, setMessages] = useState(selectedChatHistoryValue?.chats || []);
  const [loading, setLoading] = useState(false); // this is while a prompt is being processed at GPT
  const [stateBindToDeploymentModel, setStateBindToDeploymentModel] = useState({ deployment: null });
  const [form] = Form.useForm();
  const [openParameter, setOpenParameter] = useState(false);

  const { userContext, setUserContext } = useContext(UserContext);
  const user = userContext.user;
  const [api, contextHolder] = notification.useNotification();
  const chatBoxRef = useRef(null);
  const inputPromptRef = useRef(null);
  const [editItemId, setEditItemId] = useState(false);

  useEffect(() => {
    if(chatBoxRef){
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);


  useEffect(() => {
    //in edit mode we will get cost and
    form.setFieldsValue(formInitValues);
    setStateBindToDeploymentModel(() => getNewState(formInitValues));
    setSelectedChatHistoryValue({ chats: formInitValues.chats, id: formInitValues.id });
    setChatBoxState({
      sessionStartIndex: 0,
      showUpload: ["gemini-15-flash", "gpt-4-vision-preview"].includes(stateBindToDeploymentModel.deployment),
      uploadFileTypeSupported: stateBindToDeploymentModel.uploadFileTypeSupported,
    });
  }, [formInitValues]);

  useEffect(() => {
    updateSideMenu();
    updateChatbox();
  }, [user]);

  useEffect(() => {
    //load from history if current messages are blank

    if (selectedChatHistoryValue.chats) {
      setMessages(selectedChatHistoryValue.chats);
    }

    setChatBoxState((p) => {
      return {
        ...p,
        showUpload: ["gemini-15-flash", "gpt-4o"].includes(stateBindToDeploymentModel.deployment),
      };
    });
    setShowChatLoader(false)
  }, [selectedChatHistoryValue, stateBindToDeploymentModel.deployment]);

  const updateChatbox = async (selectedChatHistoryValue, chatHistoryId) => {
    //let selectedChatHistoryValue = null;
    // if (sessionStorage.getItem("selectedChatHistory") != null) {
    //   selectedChatHistoryValue = JSON.parse(
    //     sessionStorage.getItem("selectedChatHistory")
    //   );
    if (selectedChatHistoryValue) {
      const dep = getDeploymentByValue(selectedChatHistoryValue.deployment);
      const values = {
        deployment: selectedChatHistoryValue.deployment,
        deployment_max_token_limit: dep?.max_token_limit,
        ...selectedChatHistoryValue.params[0],
        chats: selectedChatHistoryValue.chats,
        id: selectedChatHistoryValue.id,
        usageInstructions: dep?.usageInstructions,
        deprecate: dep?.deprecate || false,
      };

      setFormInitValues({ ...values });
      setSelectedKeys([selectedChatHistoryValue.id.toString()]);
      setUserContext({ ...userContext, activeChatHistoryId: chatHistoryId });
    } else {
      setFormInitValues(getFormDefaultValues());
    }
  };

  const updateSideMenu = async (refreshClicked = false) => {
    setShowLoaderInSideMenu(true);
    if (!user || (!refreshClicked && navItems && navItems.length)) {
      setShowLoaderInSideMenu(false);
      return;
    }
    //call api
    getAllChatTitlesForUser(user).then((data) => {
      if (data == null || data.code) return;

      var uiArray = [];
      data.forEach((item) => {
        uiArray.push({
          key: item.id,
          label: item.name,
        });
      });
      setNavItems(uiArray);
      setShowLoaderInSideMenu(false);
    });
  };

  const onNewChatClick = async (e, deployment) => {
    setEditItemId([])
    if (!user) {
      console.log("User is null.");
      return;
    }
    setShowChatLoader(false);
    setMessages([])
    setShowLoaderInSideMenu(true);
    setFileList([]);
    setNewUserMessage(defaultMultiModalMessage());
    let newChatTitle = "new Chat- " + Math.trunc(Math.random() * 100000);
    //save the details in chat history api
    createNewChatHistory(user, newChatTitle, deployment || formInitValues.deployment, defaultParams).then((data) => {
      if (data.id) {
        // storage update
        //sessionStorage.setItem("selectedChatHistory", JSON.stringify(data));
        updateChatbox(data, data.id);
        updateSideMenu(true);
      } else {
        // show error here.
        const msgToShow = data.message || "Error in starting new chat";
        openNotificationWithIcon({
          type: "error",
          message: "Error",
          description: msgToShow,
        });
        setShowLoaderInSideMenu(false)
      }
    }).finally(e=>{
      setShowChatLoader(false);
    });
  };

  const openNotificationWithIcon = ({ type, message, description, duration = 8 }) => {
    api[type]({ message, description, duration });
  };

  const removeFirstElement = (arr, condition) => {
    const index = arr.findIndex(condition);
    if (index === -1) {
      return arr;
    }
    if (index == 0) {
      arr.shift();
    }
    return arr;
  };

  const createBodyForRawChatCompletions = (values, messagesToSendToServer) => {
    let providerType = "AzureOpenAI";
    if (["gemini-15-flash", "gemini-pro"].includes(values.deployment)) {
      providerType = "GoogleBard";
    }

    const body = {
      prompt: values.prompt,
      temperature: values.temperature,
      top_p: values.top_p,
      frequency_penalty: values.frequency_penalty,
      presence_penalty: values.presence_penalty,
      max_tokens: values.max_tokens,
      stop: values.stop,
      messages: messagesToSendToServer,
      provider_type: providerType,
    };
    //best_of is not a valid argument for model "gpt-35-turbo-chatgpt"
    if (!["gpt-35-turbo-chatgpt", "gpt-4o"].includes(values.deployment)) {
      body["best_of"] = values.best_of;
    }
    return body;
  };

  const sendMessage = async (content = "") => {
    if (selectedChatHistoryValue.id == null || selectedChatHistoryValue.id == 0) {
      openNotificationWithIcon({
        type: "error",
        message: "Start a new chat first, or select from existing ones.",
        description: "Please click on new chat button from side menu to start a new conversation. You can also select one from existing.",
      });
      return;
    }
    if (content.trim() === "") {
      openNotificationWithIcon({
        type: "error",
        message: "Invalid or Empty Prompt",
        description: "Write Something in the prompt input box",
      });
      return;
    }
    const nextMessage = newUserMessage;
    const messageForUIThread = {
      role: "user",
      content: "",
    };
    if (chatBoxState.showUpload) {
      //this is multimodal
      //text will be contentItem node
      const m = {
        ContentType: "Text",
        Text: content.trim(),
      };

      nextMessage.contentItems.unshift(m);
      //update UI after the call is done
      //in UI we will append {role,content}
      //while for server {role, contentitems}
      let formatMessage = "";
      nextMessage.contentItems.forEach((node) => {
        if (node.ContentType == "Text") {
          formatMessage = node.Text;
        } else if (node.ContentType == "InlineData") {
          formatMessage = formatMessage + "\n#MMM#" + `data:${node.InlineData.Mimetype};base64,${node.InlineData.Data}`;
        }
      });
      messageForUIThread.content = formatMessage;
    } else {
      nextMessage.content = content.trim();
      messageForUIThread.content = content.trim();
    }
    let entireFormValues = form.getFieldValue();

    //here check if we have contentitems as well
    let messagesToSendToServer = [];

    //max items to take in session.

    let msgsToAddCount = entireFormValues.max_history;

    //Add last x messages for context

    for (let index = messages.length - 1; index >= chatBoxState.sessionStartIndex; index--) {
      if (msgsToAddCount == 0) break;
      let msg = messages[index];
      msg = {
        ...msg,
        content: msg.role === "user" && msg.content ? msg.content.split("#MMM#")[0] : msg.content,
      };
      messagesToSendToServer.push(msg);
      msgsToAddCount--;
    }

    messagesToSendToServer = messagesToSendToServer.reverse();
    messagesToSendToServer.push(nextMessage);

    //in case of gemini-pro model we cannot have first message with user role
    if (["gemini-15-flash", "gemini-pro"].includes(entireFormValues.deployment)) {
      messagesToSendToServer = removeFirstElement(messagesToSendToServer, (elm) => elm.role !== "user");
    }

    setNewUserMessage(defaultMultiModalMessage());
    
    setMessages((prevMessages) => [...prevMessages, messageForUIThread]);
    setFileList([]);
    try {
      setLoading(true);
      // Call Api for response
      rawChatCompletions(
        entireFormValues.deployment,
        createBodyForRawChatCompletions(
          entireFormValues, messagesToSendToServer
        )
      ).then((data) => {
        if (data.choicesText) {
          //update chat history of the current chat if response is fine
          var returnMsg = data.choicesText[0];
          // let contentMessageParts = messageForUIThread.content.split("#MMM#");
          // let cM = ''
          // contentMessageParts.forEach((m, i) => {
          //   if (i > 0) {
          //     cM = cM+ '#MMM#' + m // '#PLH#'
          //   }else{
          //     cM = m;
          //   }
          // });
          // let lastUserMessageToAppendInChatThread = {
          //   ...messageForUIThread,
          //   content: cM
          // };

          setMessages((prevMessages) => [...prevMessages, returnMsg]);
          updateChatThreadById(selectedChatHistoryValue.id, [messageForUIThread, returnMsg]);
          setInput("");
        } else {
          // show error here.
          const msgToShow = data.message || "Error in new chat";
          openNotificationWithIcon({
            type: "error",
            message: "Error",
            description: msgToShow,
          });
          removeLastMessageOnError();
        }
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching bot response:", error);
      openNotificationWithIcon({
        type: "error",
        message: "Something went wrong",
        description: error.message,
      });
      setLoading(false);
      removeLastMessageOnError();
    }
  };

  const removeLastMessageOnError = () => {
    //remove the last message from the history if it is of role=user
    setMessages((prevMessages) => {
      if (prevMessages.length > 0 && prevMessages[prevMessages.length - 1].role === "user") {
        prevMessages.pop();
        return [...prevMessages];
      }
      return [...prevMessages];
    });
  };

  const onDeploymentChange = (value, option) => {
    onNewChatClick(null, value);
    openNotificationWithIcon({
      type: "info",
      message: "Session reset",
      description: "New chat session has started",
    });
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  const handleKeyPress = (event) => {
    if (!event.shiftKey && event.key === "Enter" && !stateBindToDeploymentModel.deprecate) {
      event.preventDefault();
      if (input.trim() === "") {
        openNotificationWithIcon({
          type: "error",
          message: "Invalid or Empty Prompt",
          description: "Write Something in the prompt input box",
        });
        return;
      }
      if (selectedChatHistoryValue.id == null || selectedChatHistoryValue.id == 0) {
        suggestedCardHandle(input)
      }
      else{
        sendMessage(input);
        setInput("");
      }

    }
  };


  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });

  const onFileUploadChange = async (info) => {
    //console.log(info);

    //validations
    //Goolge Visiosn: should allow  text, image, video & pdf rest return false
    //both image and video are not allowed
    //GPT: should allow text, and image
    let images = info.fileList.filter((f) => f.type.startsWith("image/"));
    let videos = info.fileList.filter((f) => f.type.startsWith("video/"));
    let pdfs = info.fileList.filter((f) =>
      f.type.startsWith("application/pdf")
    );
    if (stateBindToDeploymentModel.deployment === "gemini-15-flash") {
      if (
        !info.file.type.startsWith("image/") &&
        !info.file.type.startsWith("application/pdf") &&
        !info.file.type.startsWith("video/")
      ) {
        openNotificationWithIcon({
          type: "error",
          message: "Only supports image, pdf & video",
        });
        return false;
      }

      if ((images.length > 0 || pdfs.length > 0) && videos.length > 0) {
        openNotificationWithIcon({
          type: "error",
          message: "Video's can accompany only text message",
        });
        return false;
      }
      if (videos.length > 1) {
        openNotificationWithIcon({
          type: "error",
          message: "Supports one video file",
        });
        return false;
      }
    } else if (!info.file.type.startsWith("image/")) {
      openNotificationWithIcon({
        type: "error",
        message: "Only supports image",
      });
      return false;
    }

    setFileList([...images, ...pdfs, ...videos]);
    const nextContentItem = {
      ContentType: "InlineData",
      InlineData: {
        Mimetype: null,
        Data: null,
      },
    };
    if (!info.file.status && info.file.status !== "removed") {
      nextContentItem.InlineData.Mimetype = info.file.type;
      nextContentItem.InlineData.Data = await getBase64(info.file);
      setNewUserMessage((prev) => {
        const cItems = [...prev.contentItems, nextContentItem];
        return {
          ...prev,
          contentItems: cItems,
        };
      });
    }
  };

  const suggestionSendMessage = (content, chatData) => {
    if (chatData.id == null || chatData.id == 0) {
      openNotificationWithIcon({
        type: "error",
        message: "Start a new chat first, or select from existing ones.",
        description: "Please click on new chat button from side menu to start a new conversation. You can also select one from existing.",
      });
      return;
    }
    if (content.trim() === "") {
      openNotificationWithIcon({
        type: "error",
        message: "Invalid or Empty Prompt",
        description: "Write Something in the prompt input box",
      });
      return;
    }
    const nextMessage = newUserMessage;
    const messageForUIThread = {
      role: "user",
      content: "",
    };
    if (chatBoxState.showUpload) {
      //this is multimodal
      //text will be contentItem node
      const m = {
        ContentType: "Text",
        Text: content.trim(),
      };

      nextMessage.contentItems.unshift(m);
      //update UI after the call is done
      //in UI we will append {role,content}
      //while for server {role, contentitems}
      let formatMessage = "";
      nextMessage.contentItems.forEach((node) => {
        if (node.ContentType == "Text") {
          formatMessage = node.Text;
        } else if (node.ContentType == "InlineData") {
          formatMessage = formatMessage + "\n#MMM#" + `data:${node.InlineData.Mimetype};base64,${node.InlineData.Data}`;
        }
      });
      messageForUIThread.content = formatMessage;
    } else {
      nextMessage.content = content.trim();
      messageForUIThread.content = content.trim();
    }
    let entireFormValues = form.getFieldValue();

    //here check if we have contentitems as well
    let messagesToSendToServer = [];

    //max items to take in session.

    let msgsToAddCount = entireFormValues.max_history;

    //Add last x messages for context

    for (let index = messages.length - 1; index >= chatBoxState.sessionStartIndex; index--) {
      if (msgsToAddCount == 0) break;
      let msg = messages[index];
      msg = {
        ...msg,
        content: msg.role === "user" && msg.content ? msg.content.split("#MMM#")[0] : msg.content,
      };
      messagesToSendToServer.push(msg);
      msgsToAddCount--;
    }
    messagesToSendToServer = messagesToSendToServer.reverse();
    messagesToSendToServer.push(nextMessage);

    //in case of gemini-pro model we cannot have first message with user role
    if (["gemini-15-flash", "gemini-pro"].includes(entireFormValues.deployment)) {
      messagesToSendToServer = removeFirstElement(messagesToSendToServer, (elm) => elm.role !== "user");
    }
    setNewUserMessage(defaultMultiModalMessage());

    // setMessages((prevMessages) => [...prevMessages, messageForUIThread]);
    setFileList([]);
    try {
      setLoading(true);
      // Call Api for response
      rawChatCompletions(
        entireFormValues.deployment,
        createBodyForRawChatCompletions(
          entireFormValues, messagesToSendToServer
        )
      ).then((data) => {
        if (data.choicesText) {
          //update chat history of the current chat if response is fine
          var returnMsg = data.choicesText[0];
          // let contentMessageParts = messageForUIThread.content.split("#MMM#");
          // let cM = ''
          // contentMessageParts.forEach((m, i) => {
          //   if (i > 0) {
          //     cM = cM+ '#MMM#' + m // '#PLH#'
          //   }else{
          //     cM = m;
          //   }
          // });
          // let lastUserMessageToAppendInChatThread = {
          //   ...messageForUIThread,
          //   content: cM
          // };

          setMessages((prevMessages) => [...prevMessages, returnMsg]);
          setShowLoaderInSideMenu(true)
          updateChatThreadById(chatData.id, [messageForUIThread, returnMsg]);
          setInput("");
          if (chatData) {
            const dep = getDeploymentByValue(chatData.deployment);
            const values = {
              deployment: chatData.deployment,
              deployment_max_token_limit: dep?.max_token_limit,
              ...chatData.params[0],
              chats: [messageForUIThread, returnMsg],
              id: chatData.id,
              usageInstructions: dep?.usageInstructions,
              deprecate: dep?.deprecate || false,
            };
      
            setFormInitValues({ ...values });
            setUserContext({ ...userContext, activeChatHistoryId: chatData.id });
          }
          getAllChatTitlesForUser(user).then((data) => {
            if (data == null || data.code) return;
      
            var uiArray = [];
            data.forEach((item) => {
              uiArray.push({
                key: item.id,
                label: item.name,
              });
            });
            setSelectedKeys([chatData.id.toString()]);
            setNavItems(uiArray);
            setShowLoaderInSideMenu(false);
          });
        } else {
          // show error here.
          const msgToShow = data.message || "Error in new chat";
          openNotificationWithIcon({
            type: "error",
            message: "Error",
            description: msgToShow,
          });
          removeLastMessageOnError();
        }
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching bot response:", error);
      openNotificationWithIcon({
        type: "error",
        message: "Something went wrong",
        description: error.message,
      });
      setLoading(false);
      removeLastMessageOnError();
    }
  }

  const suggestedCardHandle = async (content) => {
    if (!user) {
      console.log("User is null.");
      return;
    }
    //save the details in chat history api
    if (selectedChatHistoryValue.id == null || selectedChatHistoryValue.id == 0) {
      setLoading(true)
      setInput('')
      setMessages([{
        role: "user",
        content: content,
      }]);
      let newChatTitle = "new Chat- " + Math.trunc(Math.random() * 100000);
      createNewChatHistory(user, newChatTitle, formInitValues.deployment, defaultParams).then((data) => {
        setShowChatLoader(false);
        if (data.id) {
          suggestionSendMessage(content, data)
        } else {
          // show error here.
          const msgToShow = data.message || "Error in starting new chat";
          openNotificationWithIcon({
            type: "error",
            message: "Error",
            description: msgToShow,
          });
          setShowLoaderInSideMenu(false)
        }
      });
    }
    else{
      sendMessage(content)
    }
  }

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (selectedChatHistoryValue.id == null || selectedChatHistoryValue.id == 0) {
      suggestedCardHandle(input)
    }
    else{
      sendMessage(input)
    }
  }

  const onDeleteChatHistoryClick = async (e, chatHistoryId) => {
    e.stopPropagation();
    confirm({
      title: "Confirm delete?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: {type: "default"},
      onOk() {
        deleteChatHistoryById(chatHistoryId).then((result) => {
          updateSideMenu(true);
          if(selectedKeys?.length && selectedKeys.includes(String(chatHistoryId))){
            updateChatbox();
          }
        });
      },
    });
    
  };
  const onChatTitleClick = async (chatHistoryId) => {
    if (chatHistoryId <= 0 || (selectedKeys?.length && selectedKeys.includes(chatHistoryId))) return;
    setShowChatLoader(true);
    getChatHistoryById(chatHistoryId).then((data) => {
      if (data == null || data.code) return;
      updateChatbox(data);
      setInput('')
      setFileList([])
      setNewUserMessage(defaultMultiModalMessage())
    });
  };
  const onChatTitleTextUpdate = async (chatHistoryId, newTitle) => {
    var resp = await updateChatTitleById(chatHistoryId, newTitle);
    if (!resp.code && resp.id == chatHistoryId) {
      setNavItems((existingItems) => existingItems.map((item) => (item.key == chatHistoryId ? { ...item, label: newTitle } : item)));
    }
  };

  return (
    <LayoutStyled collapsed={collapsed}>
      {contextHolder}
      {isLoggedIn && (
        <SideBar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          updateChatbox={updateChatbox}
          selectedKeys={selectedKeys}
          updateSideMenu={updateSideMenu}
          showLoaderInSideMenu={showLoaderInSideMenu}
          setShowLoaderInSideMenu={setShowLoaderInSideMenu}
          setNavItems={setNavItems}
          navItems={navItems}
          promptsList={promptsList}
          setPromptsList={setPromptsList}
          addNewHandler={onNewChatClick}
          showChatLoader={showChatLoader}
          setShowChatLoader={setShowChatLoader}
          setInput={setInput}
          setFileList={setFileList}
          setNewUserMessage={setNewUserMessage}
          selectedMenu={["1"]}
          showAddButton={true}
        >
          <div className="bottom-nav-container">
            <h3 className="chat-history">Chat History</h3>
            <Menu
              mode="inline"
              defaultSelectedKeys={selectedKeys ? selectedKeys[0] : []}
              selectedKeys={selectedKeys ? selectedKeys[0] : []}
              //items={navItems}
              onClick={(item) => onChatTitleClick(item.key)}
              className="sub-nav-list"
            >
              {showLoaderInSideMenu && !collapsed ? (
                <SkeletonStyled active />
              ) : (
                navItems?.map(({ label, key }) => (
                  <Menu.Item key={key} disabled={showChatLoader}>
                    <Tooltip title={label} placement="right">
                      <span className="nav-label">{label}</span>
                    </Tooltip>
                    <EditableChatName editItemId={editItemId} setEditItemId={setEditItemId} targetId={key} defaultValue={label} onChatTitleTextUpdateFunc={onChatTitleTextUpdate} onDeleteChatHistoryClick={onDeleteChatHistoryClick} />
                  </Menu.Item>
                ))
              )}
            </Menu>
          </div>
        </SideBar>
      )}
      <FormStyled layout="vertical" form={form} name="basic" initialValues={formInitValues} collapsed={collapsed}>
        <UserNavDropdown
          isLoggedIn={isLoggedIn}
          handleLogout={handleLogout}
          // user={user}
          // setUser={setUser}
        />
        <div className="message-container" ref={chatBoxRef}>
          {
            showChatLoader ?
            <EmptyChatLoader />
            :
            messages.length
            ? 
            <ChatsBox messages={messages} loading={loading} setInput={setInput} inputPromptRef={inputPromptRef} />
            : 
            <NewChatBox 
              sendMessage={sendMessage} 
              suggestedCardHandle={suggestedCardHandle} disabled={showLoaderInSideMenu} /> 
          }
        </div>
        <InputPromptBox 
          handleInputChange={handleInputChange} input={input} 
          handleKeyPress={handleKeyPress} setOpenParameter={setOpenParameter} 
          openParameter={openParameter} handleSendMessage={handleSendMessage} 
          onDeploymentChange={onDeploymentChange} deployments={deployments} 
          inputPromptRef={inputPromptRef}
          chatBoxState={chatBoxState}
          fileList={fileList}
          stateBindToDeploymentModel={stateBindToDeploymentModel}
          onFileUploadChange={onFileUploadChange}
        />
        <ChatParameter stateBindToDeploymentModel={stateBindToDeploymentModel} setOpenParameter={setOpenParameter} openParameter={openParameter}></ChatParameter>
      </FormStyled>
    </LayoutStyled>
  );
};

const FormStyled = styled(Form)`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  /* margin-left: ${(props) => (!props.collapsed ? "0px" : "-100px")}; */
  transition: all 0.2s linear;
  @media (max-width: 1024px){
    padding: 0 10px;
  }
  .message-container{
    overflow-y: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    flex: 1 1 auto;
  }
`;

const LayoutStyled = styled(Layout)`
  height: 100vh;
  .ant-layout-sider-collapsed{
    .top-nav-container{
      padding-right: 0px;
    }
    .ant-menu-item, .chat-history{
      opacity: 0;
      visibility: hidden;
    }
    .ant-menu,
    .bottom-nav-container {
      overflow: hidden;
    }
  }
  .ant-input:focus{
    box-shadow: none;
    outline: none;
  }
  input,
  textarea {
    &::placeholder {
      color: ${(props) => props.theme.colors.placeHolder};
    }
  }
  & * {
    &::-webkit-scrollbar-track {
      /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: rgba(0,0,0,0.3); */
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;
const SkeletonStyled = styled(Skeleton)`
  padding: 10px;
`;

export default ChatGPT;
