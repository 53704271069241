import React, { useState, useEffect, useContext } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Dropdown, Space, Avatar, Button } from "antd";
import styled from "styled-components";
import { getUserProfile } from "../../service";
import UserContext from "../../context";

const UserNavDropdown = ({ isLoggedIn, handleLogout }) => {
  const items = [
    {
      label: "Logout",
      key: "1",
    },
  ];
 
  const {userContext, setUserContext} = useContext(UserContext)
  const user = userContext.user;
  useEffect(() => {
    if (isLoggedIn) {
      getUserProfile()
        .then((_user) => {
          if (!_user) {
            handleLogout();
          }
          setUserContext({...userContext, user: _user})
          //setUser(user);
        })
        .catch((err) => {
          console.log("Authentication failed", err.code);
          //setUser(null);
          setUserContext({...userContext, user: null})
          handleLogout();
        });
    }
  }, [isLoggedIn]);

  return (
    user && (
      <HeaderStyled>
        <DropdownUI
          menu={{
            items,
            onClick: (e) => {
              switch (e.key) {
                case "1": {
                  //setUser(null);
                  setUserContext({...userContext, user: null})
                  handleLogout();
                  break;
                }
              }
            },
          }}
          trigger={["click"]}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
            }} className="avatar-wrapper" shape={"circle"}
            
          >
            {
              user?.picture ? <img src={user.picture} />
              :<Avatar icon={<UserOutlined />} />
            }
            
          </Button>
        </DropdownUI>
      </HeaderStyled>
    )
  );
};

const HeaderStyled = styled.header`
  background: transparent;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  width: 100%;
  padding: 10px 10px 0px 0px;
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.bodyBg};
  z-index: 2;
  .avatar-wrapper{
    width: 32px;
    height: 32px;
    overflow: hidden;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .avatar-wrapper > *{
    border: none;
  }
`
const DropdownUI = styled(Dropdown)`
  cursor: pointer;
  .ant-avatar.ant-avatar-icon{
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

export default UserNavDropdown;
