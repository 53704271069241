import React from "react";
import { Avatar, Skeleton } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";


const EmptyChatLoader = () => {
  return (
    <EmptyChatLoaderStyled>
      <div className={`message right`}>
        <Avatar icon={<UserOutlined />} />
        <Skeleton active paragraph={{rows: 1}} />
      </div>
      <div className={`message left`}>
        <div className="avatar"></div>
        <Skeleton active />
      </div>
    </EmptyChatLoaderStyled>
  );
};

const EmptyChatLoaderStyled = styled.div`
  width: 100%;
  padding-top: 50px;
  max-width: 750px;
  margin: 0 auto;
  font-size: 1.6rem;
  .message {
    display: flex;
    margin-bottom: 40px;
    transition: all 0.5s linear;
    gap: 20px;
    align-items: flex-start;
    .ant-skeleton{
      max-width: 86%;
    }
  }
  .right {
    flex-direction: row-reverse;
    .ant-skeleton{
      .ant-skeleton-title, ul li:last-child{
        margin-left: auto;
      }
    }
  }
  .left {
    .avatar {
      background-image: url("../icon-bold.svg");
      border-radius: 50px;
      border: 1px solid rgba(87, 108, 141, 0.5);
      background-color: #fff;
      background-size: 60%;
      height: 35px;
      width: 35px;
      background-repeat: no-repeat;
      background-position: center center;
      flex-shrink: 0;
    }
  }
`

export default EmptyChatLoader;
