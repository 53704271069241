import React, { useContext } from "react";
import styled from "styled-components";
import UserContext from "../../context";
import SuggestionCard from "./SuggestionCard";

const NewChatBox = ({suggestedCardHandle, disabled}) => {

  const {userContext} = useContext(UserContext)
  const user = userContext.user;
  const suggestedInputs = [
    "What are the best practices for writing clean and maintainable code?",
    "Can you provide tips on effective time management and productivity in the workplace?",
    "What are some ways I can prioritize and organize my work day?",
    "I need to write a company wide email informing every one of a recent win we had in product and markeing."
  ]

  return (
    <NewChatBoxStyled disabled={disabled}>
      <h1>
        <span className="hello-text">Hello {user && user.name.split(' ')[0]}.</span>
        <br />I am <strong className="bold-text">BOLD</strong>'s GPT PlayGround.
      </h1>
      <div className="prompt-suggestion-cards-container">
        {
          suggestedInputs.map((item, index) =>
            <SuggestionCard key={index} item={item} suggestedCardClick={()=>suggestedCardHandle(item)} />
          )
        }
      </div>
    </NewChatBoxStyled>
  );
};

const NewChatBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  min-height: 280px;
  height: 100%;
  max-width: 750px;
  margin: 0 auto;
  pointer-events: ${props=>props.disabled && "none"};
  & h1 {
    color: rgba(87, 108, 141, 0.5);
    font-size: 42px;
    font-weight: 500;
    line-height: 1.2;

    .hello-text {
      background: ${(props) => `linear-gradient(90deg, 
                  ${props.theme.colors.brandOrange} 0%, 
                  ${props.theme.colors.brandGreen} 25%, 
                  ${props.theme.colors.brandTeal} 50%, 
                  ${props.theme.colors.brandBlue})`};
      background-clip: text;
      display: inline-block;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .prompt-suggestion-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 180px), 1fr));
    gap: 10px;

    .prompt-suggestion-card {
      border-radius: 10px;
      border: 1px solid ${props => props.theme.colors.borderColor};
      background: #e5ecf6;
      cursor: pointer;
      padding: 8px 10px;
      transition: 0.3s;
      text-wrap: balance;

      &:hover {
        box-shadow: 0 0 10px 0 rgba(71, 94, 132, 0.3);
      }
    }
  }
  .bold-text {
    background: ${(props) => `linear-gradient(to right, 
                  ${props.theme.colors.brandBlue} 25%, 
                  ${props.theme.colors.brandTeal} 25% 52%, 
                  ${props.theme.colors.brandGreen} 52% 75%, 
                  ${props.theme.colors.brandOrange} 75% 100%)`};
    background-clip: text;
    display: inline-block;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-sizing: border-box;
  }
`;

export default NewChatBox;
