import React from "react";
import { CenterBox, ContentWrapper, Wrapper } from "../components/Styles";
import { Button, Form } from "antd";
import Cookies from "js-cookie";
import styled, { keyframes } from "styled-components";
import { GoogleOutlined } from "@ant-design/icons";
import Icon from "../images/icon-google.png";
import { config } from "../constants";
//Implement google sign in here
const Logon = () => {
  const [form] = Form.useForm();
  var loginExternal = function () {
    var openUrl = `${config.API_HOST}${config.LOGON}`;
    // window.externalWindowRef = window.open(
    //   openUrl,
    //   "",
    //   "width=900,height=500,left=100"
    // );
    // return window.externalWindowRef;
    window.open(openUrl, "_self");
  };
  // var loginGoogle = function () {
  //   return new Promise(function (resolve, reject) {
  //     loginExternal();
  //     var timer = 0;
  //     if (timer > 500) reject(new Error("Please try again"));
  //     var intervalToGetStatus = setInterval(function () {
  //       var auth_user = Cookies.get("auth_user");
  //       if (auth_user) {
  //         clearInterval(intervalToGetStatus);
  //         resolve(true);
  //       }
  //       timer += 1;
  //     }, 200);
  //   });
  // };
  const handleSignon = () => {
    loginExternal();
    // loginGoogle().then(function (status) {
    //   if (status) window.location.href = "http://localhost:3000/";
    // });
  };

  const IconGoogle = () => <img src={Icon} width="20" />;
  return (
    <>
      <CenterBoxUI>
        <SquareStyle className="square-tl" />
        <SquareStyle className="square-tr" />
        <SquareStyle className="square-bl" />
        <SquareStyle className="square-br" />
        <WrapperStyle>
          <h1>
              Welcome to <strong class="bold-text">BOLD</strong>'s GPT PlayGround!
          </h1>
          <p>Please login to explore more.</p>
          <Form
            layout="vertical"
            form={form}
            name="basic"
            onFinish={handleSignon}
          >
            <ButtonStyled htmlType="submit" icon={<IconGoogle />}>
              SignIn with google
            </ButtonStyled>
          </Form>
        </WrapperStyle>
      </CenterBoxUI>
    </>
  );
};

export default Logon;

const CenterBoxUI = styled(CenterBox)`
  background:${(props) =>  `linear-gradient(to left bottom, 
                  ${props.theme.colors.brandBlue}, 
                  ${props.theme.colors.brandTeal} , 
                  ${props.theme.colors.brandGreen}, 
                  ${props.theme.colors.brandOrange})`};
  border: 0;
  border: 0;
  overflow: hidden;
`
const WrapperStyle = styled(ContentWrapper)`
  background: linear-gradient(to right bottom,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.2));
  display: flex;
  border: 0;
  box-shadow: 0 0 5px 0 rgba(22, 22, 22, 0.1);
  width: 100%;
  max-width: 500px;
  padding: 30px;
  flex-direction: column;
  gap: 20px;


  h1 {
    color: #fff;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.2;
    
  }
  p{
    color: #fff;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .bold-text {
    background: ${(props) => `linear-gradient(to right, 
                  ${props.theme.colors.brandBlue} 25%, 
                  ${props.theme.colors.brandTeal} 25% 52%, 
                  ${props.theme.colors.brandGreen} 52% 75%, 
                  ${props.theme.colors.brandOrange} 75% 100%)`};
    background-clip: text;
    display: inline-block;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-sizing: border-box;
  }

  img {
    margin-right: 10px;
  }
`;

const ButtonStyled = styled(Button)`
  height: 40px;
  border: 0;
`

const bounce = keyframes`
  0% {transform: translateY(0px) rotate(45deg)}
  50% {
      transform: translateY(20px) rotate(45deg); border-radius: 50px;
  }
  100% {transform: translateY(0px) rotate(45deg)}
`
const SquareStyle = styled.div`
  height: 100vh;
  width: 50vw;
  display: table;
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(45deg);

  &.square-tl{
    top: -80%;
    left: -10%;
    animation: ${bounce} 6s infinite ease-in-out;
    //background: linear-gradient(to top, #475e84, #475e8475);
    z-index: 50;
  }

  &.square-tr {
    top: 0%;
    right: -30%;
    animation: ${bounce} 5s infinite ease-in-out;
    //background-color: #007581;
  }
  &.square-bl {
    bottom: -70%;
    left: -15%;
    animation: ${bounce} 4s infinite ease-in-out;
    //background-color: #f8991d;
  }
  &.square-br {
    bottom: 0%;
    right: -40%;
    animation: ${bounce} 3s infinite ease-in-out;
    background: rgb(34, 34, 34, 0.1);
    //background-color: #7ea640;
  }
`
